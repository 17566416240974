<!--
TODO:
    - PROP: Timeout interval: hide modal on timeout
    - PROP: Pass option to show an error modal in error color onTimeout
    - PROP: Pass some styles / Margins / Custom Spinner perhaps
    - PROP: Option to be able to dismiss manually
-->
<template>
    <div
        class="dimmer-container position-relative"
    >
        <slot
            name="content"
            :toggle="onToggle"
        ></slot>
        <v-fade-transition>
            <div
                v-if="dialog"
                class="dimmer-modal align-center justify-center text-center pa-6"
            >
                <div
                    class="dimmer-modal-spinner"
                    :class="{
                        'position-fixed': fixedSpinner,
                        'position-absolute': absoluteSpinner
                    }"
                >
                    <div
                        v-if="!hideSpinner"
                        class="d-flex w-100 h-100 align-center justify-center mb-6"
                    >
                        <v-progress-circular
                            :color="color"
                            :size="size"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                    <div
                        class="d-flex w-100 h-100 align-center justify-center"
                    >
                        <slot
                            name="message"
                        ></slot>
                    </div>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script lang="ts" setup>
    const props = withDefaults(
        defineProps<{
            modelValue?: boolean;
            hideSpinner?: boolean;
            fixedSpinner?: boolean;
            absoluteSpinner?: boolean;
            zIndex?: number;
            color?: string;
            size?: string | number;
        }>(),
        {
            modelValue: false,
            zIndex: 1,
            color: 'primary',
            size: 42
        }
    );

    const emit = defineEmits<{
        'update:modelValue': [value: boolean];
    }>();

    const dialog = ref<boolean>(props.modelValue);

    const unWatch = watch(
        () => props.modelValue,
        (val: boolean) => {
            dialog.value = val;
        }
    );

    function onToggle(state: boolean): void {
        dialog.value = state ?? !dialog.value;
        emit('update:modelValue', dialog.value);
    }

    onBeforeUnmount(() => {
        unWatch();
    });
</script>

<style lang="scss">
.dimmer-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    z-index: v-bind(zIndex);
    background-color: rgba(var(--v-theme-surface), 0.4);

    &-spinner {
        position: sticky;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        
        &.position-absolute {
            top: 0;
            bottom: 0;
        }
    }
}
</style>
